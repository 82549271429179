<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <v-container fluid class="my-5">
      <v-row class="my-5">
        <v-col>
          <v-card elevation="2">
            <v-toolbar flat outlined color="#f2f2f2">
              <v-toolbar-title>
                <span
                  ><v-icon size="40" color="blue"
                    >mdi-clipboard-list-outline</v-icon
                  ></span
                ><span class="blue--text text-h5 font-weight-bold">Respondents</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table :headers="headers" :items="answers" :search="search" items-per-page=5>
              <template v-slot:[`item.SN`]="{ item }">
                {{ answers.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.viewAnswers`]="{ item }">
                <v-btn
                  @click="goToAnswers(item.answerJson)"
                  depressed
                  color="primary"
                  small
                  outlined
                >
                  view answers
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import Sidenav from "@/components/navigators/Sidenav.vue";
import Topnav from "@/components/navigators/Topnav.vue";

export default {
  components: {
    Sidenav,

    Topnav,
  },
  data: () => ({
    dialog: false,
    dialog1: false,
    search: "",
    roleName: "",
    permissionName: "",
    permissionCode: "",
    permissionDescription: "",
    roleId: "",
    headers: [
      {
        text: "SN",
        align: "start",
        sortable: false,
        value: "SN",
      },
      { text: "Respondent", value: "answerName" },
      { text: "Answers", value: "viewAnswers" },
    ],
  }),
  methods: {
    async goToAnswers(answerJson){
      // console.log("The item to be sent",answerJson);
      await localStorage.removeItem('surveyJson');
      await localStorage.setItem('surveyJson',answerJson);
      this.$router.push('/preview-answers/' + this.$route.params.surveyId);
    }



  },
  computed: {
    ...mapGetters({
      // rolesInMyInstitution: "getRoleInMyInstitution",
      answers: "getOverallSurveyAnswers"
    }),
  },
  mounted() {
    // this.$store.dispatch("fetchRoleInMyInstitution");
  },
};
</script>

<style scoped>
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>